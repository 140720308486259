@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --color-Primary-Text: #101320;
  --color-Ternary-Text: #6d6f77;
  --color-Bg-Grey: #fbfbfb;
  --color-Primary-Button: #f84d2f;
  --color-Primary-BHover: #cc6957;
  --color-Bg-Ternary: #ffeae4;
  --color-Bg-Secondary: #f1f1f1;
  --color-Stroke-Active: #9e9fa5;
  --color-Stroke-Secondary: #e4e5ea;
  --color-white: #ffffff;
  --color-black: #000000;
  --color-LighBlack: #333333;
}
/* .back_img{
  background-image: url('../src/assets/images/HeroSection.svg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat
} */
.darkFont {
  font-weight: 600;
  color: var(--color-Primary-Text);
}
/* LOADER */
@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
.animate-spinner {
  animation: spinner 1s linear infinite;
}
.my-custom-captcha {
  height: 43px;
  width: 360px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('../src/assets/images/CapchaBack.png');
  border-radius: 16px;
}
.hideScrollbar::-webkit-scrollbar {
  display: none;
}


.answerSection {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: opacity 0.2s ease, max-height 0.4s ease; /* Adjust the duration and timing function as needed */
}

/* Open state */
.answerSection.open {
  opacity: 1;
  max-height: 200px; /* Adjust the value as needed */
}
/* SWITCH */
.cookie-switch {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.underlineSpan {
  color: var(--color-Primary-Text);
  text-decoration: underline;
  cursor: pointer;
}


.Phone input::placeholder {
  color: #9a9a9a !important;
}

.PhoneInput {
  width: 100% !important;
}


.not-error .PhoneInputInput {
  border-top-right-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
  border: 1px solid var(--color-Stroke-Secondary);
  padding: 10px 12px !important;
  font-size: 16px !important;
  font-family: 'Outfit', sans-serif;
  color: #101320 !important;
  font-weight: 400;
  width: 100% !important;
  outline: 0px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}
.error-class .PhoneInputInput  {
  border-top-right-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
  border: 1px solid #ff0000 !important;
  padding: 10px 12px !important;
  font-size: 16px !important;
  font-family: 'Outfit', sans-serif;
  color: #101320 !important;
  font-weight: 400;
  width: 100% !important;
  outline: 0px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}






@media (max-width: 600px) {
  .my-custom-captcha {
    width: 200px;
    height: 40px;
  }
}

@layer components {
  .FLEX_CENTER {
    @apply flex items-center justify-center;
  }
  .Text24 {
    @apply text-text2Xl font-OutfitFont font-normal max_md:text-textMedium max_md:leading-[25px] max_sm:text-textSmall max_sm:leading-5;
  }
  .Text50Bold {
    @apply text-[50px] font-semibold font-OutfitFont leading-[65px] max_md:text-text3Xl max_md:leading-[40px] max_sm:text-textMedium max_sm:leading-[25px];
  }
  .Text40 {
    @apply text-subHeadingW font-OutfitFont font-semibold text-ButtonPrimary leading-[50px] max_md:text-text2Xl max_md:leading-[30px] max_sm:text-textSmall max_sm:leading-[20.16px];
  }
  .Text18Normal {
    @apply text-textNormal font-OutfitFont font-normal max_md:text-textSmall max_sm:text-textExtraSmall;
  }
  .Text20Semi {
    @apply text-textMedium font-OutfitFont font-semibold text-PrimaryT max_md:text-textSmall max_sm:text-[15px];
  }
  .Text16Normal {
    @apply text-textSmall font-OutfitFont font-normal text-TernaryT;
  }
}
